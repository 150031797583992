import React from 'react';
import "./footer.css";

function Footer() {
  return (
    <footer>

    <p>Easter egg</p>
    <p>Mention légales</p>
  </footer>
  )
}

export default Footer