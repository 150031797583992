import React from "react";
import "./portfolio.css";
// import MediaQuery from "react-responsive";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import PortfolioCard from "./PortfolioCard";
import ReserviaImage from "../../../src/portfolio_image/reservia.png";
import OhmyfoodImage from "../../../src/portfolio_image/ohmyfood.png";
import LaChouette from "../../../src/portfolio_image/lachouette.png";
import Deadpool from "../../../src/portfolio_image/deadpool.png";
import Oniroco from "../../../src/portfolio_image/oniroco.webp";
import BricoRenov from "../../../src/portfolio_image/brico-renov.png";
import MemoryGame from "../../../src/portfolio_image/memorygame.png";
import HommeDexception from "../../../src/portfolio_image/calendar.png";
import Vana from "../../../src/portfolio_image/vana.png";
import Longevity from "../../../src/portfolio_image/longevityAnalysis.jpg";
import WebBlogGenerator from "../../../src/portfolio_image/real-story.webp";
import MobileBlogGenerator from "../../../src/portfolio_image/MobileBlogGenerator.png";

// const breakpoints = {
//   desktop: "(min-width: 1025px)",
//   tablet: "(min-width: 768px) and (max-width: 1024px)",
//   phone: "(max-width: 767px)",
//  };

//  const { string, object } = React.PropTypes;
const cardsData = [
  {
    className: "Blog Generator",
    img: WebBlogGenerator,
    alt: "Site La chouette",
    card_title: "Real story, blog générator",
    card_text:
      "Full-stack Project. React, Node.js, Express, TypeGraphQL, TypeORM, Docker etc. Projet de formation repris pour être amélioré et vivre.",
    card_visual_link: "https://real-story.fr/",
    // card_video_link: "https://youtu.be/B5E9D3AfLH4l" (uncomment if needed)
    // card_code_link: "" (uncomment and add link if available)
  },
  {
    img: Vana,
    alt: "Site Fashioniza",
    card_title: "Fashioniza",
    // card_text: "Site web d'un styliste. Design et développement fait par moi même" (uncomment if needed)
    card_visual_link: "https://vana-fashion.vercel.app/",
    card_text_added: "Site web en React",
  },
  {
    img: MobileBlogGenerator,
    alt: "Blog Generator Mobile application",
    card_title: "Blog Generator Mobile application",
    card_text:
      "Full-stack Project. React, Node.js, Express, TypeGraphQL,TypeORM, Docker etc... Projet de formation",
    card_visual_link: "",
    videoLink: "https://www.youtube.com/shorts/dyL_bGY5Dws",
  },
  {
    img: OhmyfoodImage,
    alt: "Site Ohmyfood",
    card_title: "Ohmyfood",
    card_text:
      "Site HTML, CSS, SASS. Intégration et animation. Projet de formation",
    card_visual_link: "https://vehementz.github.io/PisuClement_3_23052021/",
    card_code_link: "https://github.com/Vehementz/PisuClement_3_23052021",
  },
  {
    img: MemoryGame,
    alt: "Site Memory",
    card_title: "Memory",
    card_text: "Application web en React du jeu des cartes jumelles",
    card_visual_link: "https://memory-twins.vercel.app/",
    card_code_link:
      "https://github.com/Vehementz/MemoryGame/tree/main/21-06-js-remotefr-p2-r-uv",
  },
  {
    img: Oniroco,
    alt: "Site Oniroco",
    card_title: "Oniroco",
    card_text:
      "Front-end d'un site web style e-commerce, Vanilla Javascript, Node.js Express. Projet de formation",
    card_visual_link: "https://vehementz.github.io/P5_Pisu_Clement/home.html",
    card_code_link: "https://github.com/Vehementz/P5_Pisu_Clement",
  },
  {
    img: ReserviaImage,
    alt: "Site Reservia",
    card_title: "Reservia",
    card_text: "HTML et CSS, projet d'intégration. Projet de formation",
    card_visual_link: "https://vehementz.github.io/ClementPisu_2_31032021/",
    card_code_link: "https://github.com/Vehementz/ClementPisu_2_31032021",
  },
  {
    img: Longevity,
    alt: "Huberman videos analysis. Projet de formation",
    card_title: "Data analyse",
    card_text: "Data analyse des videos d' Andrew Huberman. Projet personnel.",
    card_visual_link:
      "https://github.com/Vehementz/Notebooks/blob/main/youutube_longevity_analysis.ipynb",
    card_code_link:
      "https://github.com/Vehementz/Notebooks/blob/main/youutube_longevity_analysis.ipynb",
  },
  {
    img: LaChouette,
    alt: "Site La chouette",
    card_title: "La Chouette",
    card_text:
      "Optimisation SEO d'un site web et amélioration du design. Projet de formation",
    card_visual_link: "https://vehementz.github.io/P4_Pisu_Clement/home.html",
    card_code_link: "https://vehementz.github.io/P4_Pisu_Clement/home",
  },
  // {
  //   img: Deadpool,
  //   alt: "Site Deadpool",
  //   card_title: "Deadpool",
  //   card_text: "Creation d'un site sur deadpool en groupe",
  //   card_visual_link: "https://vehementz.github.io/Deadpool/",
  //   card_code_link: "https://github.com/Vehementz/Deadpool",
  //   card_textAdded: "Je précise ce n'est pas moi qui ai écrit des bétises. J'ai codé le loader, le slider et participé à d'autre fonctionnalités"
  // },
  // {
  //   img: BricoRenov,
  //   alt: "Site Brico Renov",
  //   card_title: "Brico Renov",
  //   card_text: "Format desktop seulement",
  //   card_visual_link: "https://quizzical-franklin-6f744e.netlify.app/"
  // },
  // {
  //   img: HommeDexception,
  //   alt: "Site Homme d'exception",
  //   card_title: "Homme d'exception",
  //   card_text: "Nextjs, création du front-end d'un agenda",
  //   card_visual_link: "https://homme-dexception.vercel.app/Clement"
  // }
];

const PortfolioItem = ({ cardData, index }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "0px",
  });

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={variants}
      transition={{ duration: 0.3, delay: 0.2 }}
    >
      <PortfolioCard {...cardData} />
    </motion.div>
  );
};

export default function Portfolio() {
  return (
    <div className="portfolio">
      <h1 className="portfolio_header_title">Réalisations</h1>

      {cardsData.map((card, index) => (
        <PortfolioItem cardData={card} index={index} key={index} />
      ))}

      {/* <PortfolioCard className="chouette" img={LaChouette} alt="Site La chouette"
        card_title="La Chouette"
        card_text="Optimisation SEO d'un site web et amélioration du design. Projet de formation"
        card_visual_link="https://vehementz.github.io/P4_Pisu_Clement/home.html"
        card_code_link="https://vehementz.github.io/P4_Pisu_Clement/home"
      /> */}

      <i className="portfolio_card invisible zero" />
      <i className="portfolio_card invisible first" />
      {/* <i className="portfolio_card second" /> */}
      <i className="portfolio_card invisible second" />
      <i className="portfolio_card invisible third" />
      <i className="portfolio_card invisible five" />
      <i className="portfolio_card invisible six" />

    </div>
  );
}
