import React from 'react';
import "./portfolioCard.css";

export default function PortfolioCard(props) {

  return (
    <div className="portfolio_card">

        <a href={props.card_visual_link} className="portfolio_card_img">
           <img className="portfolio_card_img_element" src={props.img} alt={props.card_title}/>
        </a>
        <hr className="portfolio_card_line"/>
        <div className="portfolio_card_description" >
          <h3 className="portfolio_card_description_title">{props.card_title}</h3>
          <p className="portfolio_card_description_text">{props.card_text}</p>
          <p className="protfolio_card_description_text_added">{props.card_text_added}</p>
        </div>
        <hr className="portfolio_card_line portfolio_card_line_bottom"/>
        <div className="portfolio_card_link"> 
          {props.card_visual_link && <a href={props.card_visual_link} target='_blank' rel="noopener noreferrer" className="portfolio_card_link_visual btn_frame btn_frame_left">Visuel</a>}
          {props.card_code_link && <a href={props.card_code_link} target='_blank' rel="noopener noreferrer" className="portfolio_card_link_code btn_frame btn_frame_right">Code</a>}
          {props.card_video_link && <a href={props.card_video_link} target='_blank' rel="noopener noreferrer" className="portfolio_card_link_visual btn_frame btn_frame_left">Vidéo</a>}
        </div>

    </div>
  )
}
