import React, { useState, useEffect } from 'react';

import { NavLink } from 'react-router-dom';
import "./navbar.css";

function Navbar() {



    const [status, setStatus] = useState('close');
    const [isDarkMode, setIsDarkMode] = useState(localStorage.getItem('dark-mode') === 'true');

    const [theme, setTheme] = useState(null);

    useEffect(() => {
        if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    }, []);

    const handleThemeSwitch = () => {
        setTheme(theme === 'dark' ? 'light' : 'dark');
    };

    useEffect(() => {
        if (theme === 'dark') {
            document.documentElement.classList.add('dark');
        } else {
            document.documentElement.classList.remove('dark');
        }
    }, [theme]);


    useEffect(() => {
        document.documentElement.classList.toggle('dark', isDarkMode);
        localStorage.setItem('dark-mode', isDarkMode);
    }, [isDarkMode]);

    const handleThemeChange = () => {
        setIsDarkMode(!isDarkMode);
    };

    const handleClick = () => {
        setStatus(!status);
        const handleClick = () => {
            setStatus(!status);
        }

    }


    return (

        <nav className="navbar">
            {/* <button
                type="button"
                onClick={handleThemeSwitch}
                className="theme-icon-container z-10 right-8 top-2 bg-indigo-500 text-lg p-1 rounded-md"
            >
                {theme === 'dark' ? '🌙' : '🌞'}
            </button> */}

            <div className="navbar_container">

                <div className={status === 'open' ? "navbar_menu active" : "navbar_menu"}>
                    {/* <li className="stylist_name"><NavLink exact to="/" onClick={handleClick} >
                        Clement
                    </NavLink></li> */}
                    <li><NavLink exact to="/home" activeClassName="active" className="navbar_links" onClick={handleClick} >
                        Presentation
                    </NavLink>
                    </li>
                    <li><NavLink exact to="/" activeClassName="active" className="navbar_links" onClick={handleClick} >
                        portfolio
                    </NavLink>
                    </li>


                    {/* 
                      <li><NavLink to="/contact" activeClassName="active" className="navbar_links" onClick={handleClick} >
                          Contact
                      </NavLink>
                      </li> */}

                </div>
                <div
                    className="BurgerMenu__container"
                    role="button"
                    onClick={() => setStatus(status === 'open' ? 'close' : 'open')}
                >
                    <i className={status}></i>
                    <i className={status}></i>
                    <i className={status}></i>
                </div>
            </div>
        </nav>

    )

    // const [toggleMenu, setToggleMenu] = useState(false);
    // const [largeur, setLargeur] = useState(window.innerWidth);   

    // const toggleNavbar = () => {
    //   setToggleMenu(!toggleMenu);
    // }

    // useEffect (() => {

    //   const changeWidth = () => {
    //     setLargeur(window.innerWidth);
    //     if(window.innerWidth > 800) {
    //       setToggleMenu(false);
    //     }
    //   }

    //   window.addEventListener('resize', changeWidth);

    //   return () => {
    //     window.removeEventListener('resize', changeWidth);
    //   }
    //   }, [])

    //   return (

    //     <nav id="navigation">

    //     {(toggleMenu || largeur > 800) && (
    //       <div className="navbar">
    //         <div className="navlink_container">
    //           <NavLink exact to="/" 
    //           className="navLink" >Presentation
    //           </NavLink>

    //           <NavLink to="/videos" 
    //           className="navLink" >Vidéos
    //           </NavLink>

    //           <NavLink to="/contact" 
    //           className="navLink" >Contact
    //           </NavLink>
    //         </div>
    //       </div>
    //     )}
    //         <button onClick={toggleNavbar} className="burger_menu">Menu</button>

    //     </nav>
    //   )
}
export default Navbar