import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './pages/Home/Home';
import News from './pages/News/News';
import Navbar from './components/Navbar/Navbar';
import Cv from './pages/CV/Cv';
import Portfolio from './pages/Portfolio/Portfolio';
import Journal from './pages/Journal/Journal';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <div className="background_site">
        <Routes>
          <Route path="/" element={<Portfolio />} />
          <Route path="/home" element={<Home />} />
          <Route path="/cv" element={<Cv />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/news" element={<News />} />
          <Route path="/journal" element={<Journal />} />
        </Routes>

      </div>
      <Footer />
    </div>
  );
}

export default App;
