import React from 'react'
import "./journal.css";

function Journal() {
  return (
    <div className='journal'>
        <div className='journal_header'>
            <h1 className="journal_header_title">
            Ici un brief du chemin
            </h1>
        </div>
    
        <main className="main_journal">

            <div class="actuality_container">
                <div className="actuality_card">
                    <h2 className='actuality_card_title'>Actualités :</h2>
                    <p className="actuality_card_text"> J'apprends Typescript en ce moment
                    </p>
                </div>

            </div>

            <div className='journal_container'>

                <h2 className='journal_container_title'>Journal</h2>

                <div className='journal_card_container'>

                    <div className='journal_card'>
                        <p className='journal_card_date'>30 Mai</p>
                        <h2 className='journal_card_title'>Groupe de partage</h2>

                        <p className='journal_card_abstract'>Abstract: Test de création d'un petit groupe hebdomadaire de partage d'expériences
                        </p>
                        <p>L'objectif est de partager des connaissances et où des recherches pertinentes, montrer des bonnes idées etc..<br/>
                        Le but n'est passocial mais pour permettre de gagner du temps sur des problèmes qui pourraient être rencontrés dans le future et prendre beaucoup de temps

                        <br/>
                        Essaie d'une première session à deux. Et validé
                        <br/>
                        Agrandissement du groupe en vue
                        <br/>
                        Une autre personne est déjà intéressé
                        </p>
                    </div>


                    <div className='journal_card'>
                        <p className='journal_card_date'>23 Mai</p>
                        <h2 className='journal_card_title'>Trouver un mentor </h2>

                        <p className='journal_card_abstract'>Abstract: Un mentor peut aider à progresser très vite. En conseillant des ressources, partageant son avis et répondre à des points précis</p>
                        <p>Je voulais trouver un mentor. Après avoir cherché sur divers sites spécialisés et n'ayant pas été convaincu 
                            Je mis simplement sur un groupe discord que je cherchais un mentor et que je rémunerai
                            <br/>
                            Moins de 10 minutes après j'avais un message
                            <br/>
                            Plannification rapide pour organiser une première séance le lendemain
                            <br/>
                            Bilan : Le mentor est vraiment très instructif son expérience m'a permis de mieux comprendre comment je pouvais réussir mes objectifs
                            professionnels et d'avoir un retour sur le site sur lequel je codais 
                            Pour l'instant nous restons focus sur le front-end
                        </p>
                    </div>

            </div>

        </div>

        </main>


    </div>
  )
}

export default Journal